export default function App()
{
  return (
    <>
      <h1 style={{ textAlign: "center", fontSize: "24px", color: "red" }}>
        This website is no longer available
      </h1>
    </>
  );
}
